export default {
  props: {
    item: Object,
    treeKey: String,
  },
  methods: {
    async callMoveApi(from, to, mode) {
      try {
        await this.treeApi.moveApi(from, to, mode)
      } catch (error) {
        console.error(error)
        this.$snotify.error(
          this.$t('notify.tree.move.failure.content'),
          this.$t('notify.tree.move.failure'),
        )
      }
    },
    clearMove() {
      this.$store.dispatch(`tree/${this.treeKey}/clearMove`)
    },
  },
  computed: {
    hasWritePermission() {
      return this.$store.getters[`tree/${this.treeKey}/hasWritePermission`]
    },
    disabled() {
      if(this.hasWritePermission) return true
      return false
    },
    movable() {
      if(!this.hasMoveNode) return true
      return this.item.id == this.moveNode
    },
    isMovingNode() {
      if(!this.hasMoveNode) return false
      return this.item.id == this.moveNode
    },
    moveNode() {
      return this.$store.getters[`tree/${this.treeKey}/moveNode`]
    },
    move() {
      return this.$store.getters[`tree/${this.treeKey}/move`]
    },
    isSameParent() {
      if(!this.moveNode) return false
      return this.moveNode.parent === this.item.parent_id
    },
    hasMoveNode() {
      return this.$store.getters[`tree/${this.treeKey}/hasMoveNode`]
    },
    treeApi() {
      return this.$store.getters[`tree/${this.treeKey}/api`]
    },
    treeActions() {
      return this.$store.getters[`tree/${this.treeKey}/actions`]
    },
    treeConfig() {
      return this.$store.getters[`tree/${this.treeKey}/config`]
    },
    treeConfigActions() {
      if(!this.treeConfig) return null
      return this.treeConfig.nodeAction
    },
    createMove() {
      if(!this.hasWritePermission) return false
      if(!this.treeConfigActions) return true
      if(typeof this.treeConfigActions.move != 'function') return true
      return this.treeConfigActions.move(this.item, this.item.depth)
    },
  }
}